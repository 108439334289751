import React, {useState} from 'react';

function Layout2(props) {
 //console.log('*........ ## ROOT RENDER', props);


  // let v = useActionData();
  return <div>
    Loader
  </div>
}

export default Layout2
